import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { BoldText } from '../../components/bold-text/bold-text';
import { ItalicText } from '../../components/italic-text/italic-text';
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Musstet ihr schon einmal Informationen aus mehreren Datenquellen oder verschiedenen API-Endpunkten abrufen? Habt ihre eine
Optimierung hinsichtlich `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Concurrency_(computer_science)"
      }}>{`Parallelität`}</a>{` und
voneinander abhängigen Datenabfragen vorgenommen?`}</p>
    <p>{`Wenn ihr mindestens eine dieser Fragen mit Ja beantwortet, solltet ihr jetzt weiterlesen. Ich zeige euch meine Lösung mit
dem `}<a parentName="p" {...{
        "href": "https://angular.io/"
      }}>{`Angular-Framework`}</a>{` und `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/rxjs"
      }}>{`RxJS`}</a>{`. Lasst eure Datenabrufe fließen
wie eine Reihe miteinander verbundener Wasserfälle.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHnMAlaa//EABoQAAIDAQEAAAAAAAAAAAAAAAECAAMhERL/2gAIAQEAAQUCrX01ilZkRysW7WA7/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFhEBAQEAAAAAAAAAAAAAAAAAABFB/9oACAECAQE/AcR//8QAHBAAAgEFAQAAAAAAAAAAAAAAAAEhAhAREjFR/9oACAEBAAY/AqX4apxm0HEcP//EABsQAQADAQADAAAAAAAAAAAAAAEAESExQWGR/9oACAEBAAE/IW4ouwLTY1Eb5+wD2Ijrbblnln//2gAMAwEAAgADAAAAEBTv/8QAFxEAAwEAAAAAAAAAAAAAAAAAARAhMf/aAAgBAwEBPxAbV//EABcRAQEBAQAAAAAAAAAAAAAAAAEAIXH/2gAIAQIBAT8QQMXV/8QAHBABAQACAwEBAAAAAAAAAAAAAREAITFBgWFx/9oACAEBAAE/ELaQbPG+PcSJ1ih+J5jhSu9OcQqgWtQnnP3GipVK3x6xl4GVW5//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Eine Reihe von Wasserfällen, die einen felsigen Abhang hinunterfließen.",
          "title": "Eine Reihe von Wasserfällen, die einen felsigen Abhang hinunterfließen.",
          "src": "/static/4a9eb7beeb95b7b1ead10bd5652aff71/e5166/pexels-quang-nguyen-vinh-waterfalls.jpg",
          "srcSet": ["/static/4a9eb7beeb95b7b1ead10bd5652aff71/f93b5/pexels-quang-nguyen-vinh-waterfalls.jpg 300w", "/static/4a9eb7beeb95b7b1ead10bd5652aff71/b4294/pexels-quang-nguyen-vinh-waterfalls.jpg 600w", "/static/4a9eb7beeb95b7b1ead10bd5652aff71/e5166/pexels-quang-nguyen-vinh-waterfalls.jpg 1200w", "/static/4a9eb7beeb95b7b1ead10bd5652aff71/b17f8/pexels-quang-nguyen-vinh-waterfalls.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Quang Nguyen Vinh / pexels.com`}</em></p>
    <p>{`Bevor wir uns einige Codebeispiele genauer ansehen, hier eine kurze Erklärung von RxJS.`}</p>
    <h2>{`Was ist RxJS und was sind Observables?`}</h2>
    <p>{`Der Name RxJS ist eine Abkürzung für `}<ItalicText mdxType="ItalicText">{`Reactive Extensions Library for JavaScript`}</ItalicText>{`. In der
offiziellen `}<a parentName="p" {...{
        "href": "https://rxjs.dev/guide/overview"
      }}>{`RxJS-Einführung`}</a>{` heißt es:`}</p>
    <blockquote lang="en">
    RxJS is a library for composing asynchronous and event-based programs by using <BoldText mdxType="BoldText">observable sequences</BoldText>.
    </blockquote>
    <p>{`Der Begriff `}<span lang="en">{`„observable sequence“`}</span>{` ist hier der Schlüssel. Er steht für die Idee einer aufrufbaren Sammlung
von zukünftigen Werten. Die entsprechende Funktionalität ist in der Klasse `}<InlineCode mdxType="InlineCode">{`Observable`}</InlineCode>{` gekapselt.`}</p>
    <p>{`Ihr könnt zum Beispiel ein Observable erstellen, das den Wert eines Eingabefeldes bei jeder Wertänderung ausgibt. Oder ihr könnt
das `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise"
      }}>{`Promise`}</a>{` eines
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch"
      }}>{`fetch-Aufrufs`}</a>{` in ein Observable verpacken, welches den
Response-Wert ausgibt, wenn das Promise abgeschlossen ist.`}</p>
    <p>{`Ein weiteres wichtiges Konzept ist die Verwendung einer `}<InlineCode mdxType="InlineCode">{`pipe`}</InlineCode>{`. Mit der `}<a parentName="p" {...{
        "href": "https://rxjs.dev/api/index/function/pipe"
      }}>{`Pipe-Funktion`}</a>{`
eines Observables können wir eine Reihe von Aktionen auf dem Observable und seinem Datenstrom ausführen. Ihr solltet auf jeden Fall
die Grundlagen von RxJS mit der offiziellen Dokumentation lernen. Ich kann auch die Website `}<a parentName="p" {...{
        "href": "https://www.learnrxjs.io/"
      }}>{`Learn RxJS`}</a>{`
empfehlen.`}</p>
    <h2>{`Die Herausforderung`}</h2>
    <p>{`In einem Kundenprojekt stand ich vor folgender Herausforderung: Die Webanwendung sollte es Nutzer:innen ermöglichen,
bestimmte Datensätze offline verfügbar zu machen. In einem ersten Schritt habe ich die App in eine
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps"
      }}>{`Progressive Web App`}</a>{` umgewandelt und einen
`}<a parentName="p" {...{
        "href": "https://angular.io/guide/creating-injectable-service"
      }}>{`Service`}</a>{` erstellt, der die Datenspeicherung mit
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Glossary/IndexedDB"
      }}>{`IndexedDB`}</a>{` übernimmt.`}</p>
    <p>{`Dann wusste ich plötzlich nicht mehr weiter!`}</p>
    <p>{`Um ehrlich zu sein: Ich musste noch nie Daten von verschiedenen API-Endpunkten abrufen, bei denen sich das Ergebnis initialer Abfragen
auf hunderte weitere Abfragen auswirken würde. Ich musste die Abfragesequenz sowohl für die Parallelität als auch für gegenseitige
Abhängigkeiten optimieren.`}</p>
    <p>{`Nach einer Menge Online-Recherche und viel Ausprobieren bin ich zu einer zufriedenstellenden Lösung gekommen. Der folgende Quellcode
ist eine angepasste Version meines Kundenprojekts, die ich gerne mit euch teilen möchte.`}</p>
    <h2>{`Die Lösung`}</h2>
    <p>{`Geht ihr gerne ins Kino? Stellt euch eine Webanwendung vor, die das Kinoprogramm für verschiedene Kinos anzeigt. Ihr könnt sehen,
wann welcher Film läuft, Informationen über die Schauspieler:innen abrufen, Filmausschnitte ansehen und durch Screenshots blättern.
Ihr könnt auch allgemeine Informationen über die Kinos (z.B. Name, Standort) und ihre Kinosäle (z.B. Anzahl der Sitzplätze) lesen.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQT/xAAVAQEBAAAAAAAAAAAAAAAAAAABA//aAAwDAQACEAMQAAAB68VJXmXA/8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIAEQMTISL/2gAIAQEAAQUC4WbEhmgNK8jothP/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwGI/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bh//EABsQAAMAAgMAAAAAAAAAAAAAAAABESExQWGB/9oACAEBAAY/AsY7KzlelGiXR//EABoQAAIDAQEAAAAAAAAAAAAAAAABESExUUH/2gAIAQEAAT8hmsTrgUSX0SE1eYNAhElrWh//2gAMAwEAAgADAAAAEFDv/8QAGBEBAQADAAAAAAAAAAAAAAAAAQARMVH/2gAIAQMBAT8Qw2Mrt//EABYRAAMAAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxB0Rn//xAAcEAEAAgMBAQEAAAAAAAAAAAABABEhMUFR0fH/2gAIAQEAAT8QqirCouvCKw2M0IQLolyB2vJkQ0nd4/Ii90gb7c2eNbX5P//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Mehrere Tüten Popcorn.",
          "title": "Mehrere Tüten Popcorn.",
          "src": "/static/ab24b446a7a73e5272934938e6cef13c/e5166/pexels-pixabay-popcorn.jpg",
          "srcSet": ["/static/ab24b446a7a73e5272934938e6cef13c/f93b5/pexels-pixabay-popcorn.jpg 300w", "/static/ab24b446a7a73e5272934938e6cef13c/b4294/pexels-pixabay-popcorn.jpg 600w", "/static/ab24b446a7a73e5272934938e6cef13c/e5166/pexels-pixabay-popcorn.jpg 1200w", "/static/ab24b446a7a73e5272934938e6cef13c/b17f8/pexels-pixabay-popcorn.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Pixabay / pexels.com`}</em></p>
    <p>{`Um all diese Daten abzurufen und offline zu speichern, müssen wir auf verschiedene Endpunkte zugreifen. Einige der Abfragen
(z.B. für jeden Film) hängen von Informationen ab, die in anderen Abfragen (z.B. für das Filmprogramm) abgerufen werden. Das bedeutet,
dass wir zunächst eine logische Reihenfolge für unsere Download-Sequenz festlegen müssen.`}</p>
    <h3>{`Schritt 1: Die zentrale Download-Pipe`}</h3>
    <p>{`In meinem Projekt enthält die Klasse `}<InlineCode mdxType="InlineCode">{`CinemaDownloadService`}</InlineCode>{` die gesamte Download-Logik. Der Service kann z.B.
von einer `}<a parentName="p" {...{
        "href": "https://angular.io/api/core/Component"
      }}>{`Angular-Komponente`}</a>{` eingebunden werden. Er bietet die öffentliche
Methode `}<InlineCode mdxType="InlineCode">{`downloadCinemaInfo`}</InlineCode>{`, die alle notwendigen Informationen herunterlädt, um ein Kino und sein
Filmprogramm offline verfügbar zu machen:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`public downloadCinemaInfo(cinemaId: number): Observable<CinemaOfflineData> {
  return this.downloadDataForCinemaId(cinemaId).pipe(
    switchMap(this.downloadMovies),
    switchMap(this.downloadActorBiographies),
    switchMap(this.downloadFilmClips),
    switchMap(this.downloadScreenshots),
  );
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Die Methode gibt ein Observable zurück, das die Datensammlung `}<InlineCode mdxType="InlineCode">{`CinemaOfflineData`}</InlineCode>{` ausgibt, sobald alle
einzelnen Downloads abgeschlossen sind.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`export interface CinemaOfflineData {
  generalInfo: CinemaGeneralInfo;
  movieHalls: MovieHall[];
  movieProgram: MovieProgram;
  movies: Movie[];
  actorBios: Actor[];
  filmClips: FilmClip[];
  screenshots: MovieScreenshot[];
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Um die Daten zu sammeln, lädt die Methode zunächst alle Informationen herunter, die direkt von `}<InlineCode mdxType="InlineCode">{`cinemaId`}</InlineCode>{`
abhängen. Wir werden uns das gleich genauer ansehen. Anschließend verwendet sie eine `}<InlineCode mdxType="InlineCode">{`pipe`}</InlineCode>{` und
den `}<InlineCode mdxType="InlineCode">{`switchMap`}</InlineCode>{`-Operator, um die restlichen Daten herunterzuladen.`}</p>
    <p>{`Der `}<a parentName="p" {...{
        "href": "https://rxjs.dev/api/index/function/switchMap"
      }}>{`switchMap-Operator`}</a>{` verwendet den vom Observable ausgegebenen Wert, um ein
neues Observable zu erstellen, welches das alte ersetzt. Kurz gesagt: Sobald wir mit einem bestimmten Download-Vorgang fertig sind,
starten wir einen neuen, der das Ergebnis des vorherigen Vorgangs enthält.`}</p>
    <h3>{`Schritt 2: Basis-Informationen herunterladen`}</h3>
    <p>{`In einem ersten Schritt wollen wir die allgemeinen Informationen über das Kino, seine Kinosäle und das aktuelle Kinoprogramm
herunterladen:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`private downloadDataForCinemaId(cinemaId: number): Observable<CinemaOfflineData> {
  return forkJoin([
    this._request.getCinemaGeneralInfo(cinemaId).pipe(
      retryStrategy()
    ),
    this._request.getMovieHalls(cinemaId).pipe(
      retryStrategy()
    ),
    this._request.getMovieProgram(cinemaId).pipe(
      retryStrategy()
    ),
  ]).pipe(
    map(([generalInfo, movieHalls, movieProgram]: [CinemaGeneralInfo, MovieHall[], MovieProgram]) => {
      const data: CinemaOfflineData = {
        generalInfo, movieHalls, movieProgram,
        movies: [], actorBios: [],
        filmClips: [], screenshots: [],
      };
      return data;
    })
  );
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Die einzelnen Backend-Abfragen werden über einen eigenständigen `}<InlineCode mdxType="InlineCode">{`RequestService`}</InlineCode>{` gestellt, auf den wir
über `}<InlineCode mdxType="InlineCode">{`this._request`}</InlineCode>{` zugreifen. Die Details der Implementierung sind für diesen Beitrag nicht relevant. Alles,
was ihr wissen müsst, ist: Jede Request-Methode gibt ein Observable zurück, das den Response ausgibt und dann abgeschlossen wird.`}</p>
    <p>{`Wir verwenden den Operator `}<a parentName="p" {...{
        "href": "https://rxjs.dev/api/index/function/forkJoin"
      }}>{`forkJoin`}</a>{`, um abzuwarten, bis alle Abfragen beendet sind.
Erst dann erstellen wir die erste Instanz unseres `}<InlineCode mdxType="InlineCode">{`CinemaOfflineData`}</InlineCode>{`-Objekts. Die übrigen Eigenschaften,
wie `}<InlineCode mdxType="InlineCode">{`movies`}</InlineCode>{`, werden als leere Arrays initialisiert. Wir werden die entsprechenden Daten in den nächsten
Schritten unserer Download-Pipe herunterladen.`}</p>
    <h3>{`Schritt 3: Zeitgleiche Requests mit `}<InlineCode mdxType="InlineCode">{`mergeMap`}</InlineCode>{` begrenzen`}</h3>
    <p>{`Nachdem wir das Kinoprogramm erfolgreich heruntergeladen haben, wollen wir Infos über alle darin enthaltenen Filme abrufen.
Je nach Größe des Kinos und des abgedeckten Zeitraums könnte dies Hunderte von Einzelabfragen bedeuten.`}</p>
    <p>{`Das moderne `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Glossary/HTTP_2"
      }}>{`HTTP/2-Protokoll`}</a>{` unterstützt das vollständige Multiplexing
von Abfragen. Je nach eurem Backend-Server und der `}<a parentName="p" {...{
        "href": "https://medium.com/geekculture/how-to-calculate-server-max-requests-per-second-38a39bb96a85"
      }}>{`maximalen Anzahl von Abfragen pro Sekunde`}</a>{`
kann es jedoch sinnvoll sein, die Anzahl der gleichzeitigen Abfragen zu begrenzen.`}</p>
    <p>{`Um dies zu erreichen, verwenden wir eine Kombination der folgenden RxJS-Funktionen: `}<InlineCode mdxType="InlineCode">{`from`}</InlineCode>{`, `}<InlineCode mdxType="InlineCode">{`mergeMap`}</InlineCode>{`
und `}<InlineCode mdxType="InlineCode">{`reduce`}</InlineCode>{`. Hier ist die vollständige Methode zum Herunterladen von Filminfos:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`private downloadMovies = (data: CinemaOfflineData): Observable<CinemaOfflineData> => {
  const movies: Movie[] = [];
  const movieIds = this.getMovieIds(data.movieProgram);
  return from(movieIds).pipe(
    mergeMap(
      id => this._request.getMovieInfo(id).pipe(
        retryStrategy()
      ),
      MAX_CONCURRENT_BACKEND_REQUESTS
    ),
    reduce(
      (accumulator, item) => {
        accumulator.push(item);
        return accumulator;
      },
      movies
    ),
    map(movies => ({ ...data, movies }))
  );
};`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Ich weiß, das ist ein ziemlicher Brocken! Schauen wir uns die Umsetzung Schritt für Schritt an:`}</p>
    <ol>
      <li parentName="ol">{`Wir holen uns die eindeutigen `}<InlineCode mdxType="InlineCode">{`movieIds`}</InlineCode>{` aus dem bereits heruntergeladenen Filmprogramm. Dann verwenden
wir `}<a parentName="li" {...{
          "href": "https://rxjs.dev/api/index/function/from"
        }}>{`from`}</a>{`, um das Array der Film-IDs in ein Observable zu verwandeln.`}</li>
      <li parentName="ol">{`Als Nächstes verwenden wir `}<a parentName="li" {...{
          "href": "https://rxjs.dev/api/index/function/mergeMap"
        }}>{`mergeMap`}</a>{`, um für jeden Film eine Abfrage zu erstellen.
Wir übergeben die Konstante `}<InlineCode mdxType="InlineCode">{`MAX_CONCURRENT_BACKEND_REQUESTS`}</InlineCode>{` als zweiten Parameter, um die maximale Anzahl der
gleichzeitigen Abfragen zu begrenzen.`}</li>
      <li parentName="ol">{`Jedes einzelne Request-Observable nutzt den `}<InlineCode mdxType="InlineCode">{`retryStrategy`}</InlineCode>{`-Operator, um die Abfrage im Fehlerfall zu wiederholen.
Dabei handelt es sich um meinen eigenen, benutzerdefinierten Operator, der die `}<a parentName="li" {...{
          "href": "https://rxjs.dev/api/index/function/retry"
        }}>{`retry-Funktion`}</a>{`
verwendet.`}</li>
      <li parentName="ol">{`Das nächste Element in unserer Observable-Pipe ist `}<a parentName="li" {...{
          "href": "https://rxjs.dev/api/index/function/reduce"
        }}>{`reduce`}</a>{`.
Wir verwenden diese Funktion, um die Ergebnisse aller Backend-Abfragen in dem
Array `}<InlineCode mdxType="InlineCode">{`movies`}</InlineCode>{` zusammenzufassen.`}</li>
      <li parentName="ol">{`Zuletzt integrieren wir die Filmdaten in eine neue Instanz unseres `}<InlineCode mdxType="InlineCode">{`CinemaOfflineData`}</InlineCode>{`-Objekts, indem wir
den `}<a parentName="li" {...{
          "href": "https://rxjs.dev/api/index/function/map"
        }}>{`map-Operator`}</a>{` verwenden.`}</li>
    </ol>
    <p>{`Jetzt kann die zentrale Download-Pipe zum nächsten Schritt übergehen: Den Download der Biografien aller Schauspieler:innen. Wir
ermitteln die eindeutigen `}<InlineCode mdxType="InlineCode">{`actorIds`}</InlineCode>{` aus den Filmdaten und wenden die gleiche Download-Logik wie zuvor an:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`private downloadActorBiographies = (data: CinemaOfflineData): Observable<CinemaOfflineData> => {
  const actorBios: Actor[] = [];
  const actorIds = this.getActorIds(data.movies);
  return from(actorIds).pipe(
    mergeMap(
      id => this._request.getActorBiography(id).pipe(
        retryStrategy()
      ),
      MAX_CONCURRENT_BACKEND_REQUESTS
    ),
    reduce(
      (accumulator, item) => {
        accumulator.push(item);
        return accumulator;
      },
      actorBios
    ),
    map(actorBios => ({ ...data, actorBios }))
  );
};`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Wir wiederholen dieselben Schritte für die Filmausschnitte und Screenshots.`}</p>
    <h3>{`Schritt 4: Das Download-Observable ausführen`}</h3>
    <p>{`Um die gesamte Download-Logik auszuführen, müsst ihr die öffentliche Methode `}<InlineCode mdxType="InlineCode">{`downloadCinemaInfo`}</InlineCode>{` aufrufen
und dann die subscribe-Methode für das zurückgegebene Observable aufrufen:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`this._cinemaDownloadService.downloadCinemaInfo(cinemaId)
  .subscribe((cinemaData: CinemaOfflineData) => {
    /* Store data offline, e.g., with IndexedDB */
  });`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Das war's! Ihr habt erfolgreich eine elegante Download-Logik erstellt, die für Parallelität und voneinander abhängige Daten
optimiert ist.`}</p>
    <p>{`Natürlich solltet ihr auch die Fehlerbehandlung mit `}<a parentName="p" {...{
        "href": "https://rxjs.dev/api/index/function/catchError"
      }}>{`catchError`}</a>{` implementieren.
Weiters könntet ihr auch den Download-Prozess visualisieren, indem ihr mithilfe eines `}<a parentName="p" {...{
        "href": "https://rxjs.dev/api/index/class/Subject"
      }}>{`Subject`}</a>{`
den aktuellen Status kommuniziert.`}</p>
    <h2>{`Fazit`}</h2>
    <p>{`Wie wir gesehen haben, bietet RxJS eine Reihe mächtiger Werkzeuge wie `}<InlineCode mdxType="InlineCode">{`mergeMap`}</InlineCode>{` und `}<InlineCode mdxType="InlineCode">{`reduce`}</InlineCode>{`.
Sie helfen uns, effiziente und robuste Lösungen zu implementieren.`}</p>
    <p>{`Ihr könnt eine zentrale Download-Pipe definieren, die einen schnellen Überblick über die einzelnen Schritte bietet. Dann platziert ihr
die spezifische Download-Logik in isolierten privaten Funktionen. Damit könnt ihr euren Code sauber und wartbar halten.`}</p>
    <h2>{`Nützliche Links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://rxjs.dev/"
        }}>{`RxJS (Reactive Extensions Library for JavaScript)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.learnrxjs.io/"
        }}>{`Learn RxJS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://netbasal.com/creating-custom-operators-in-rxjs-32f052d69457"
        }}>{`Creating Custom Operators in RxJS`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      